.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--v-space-32);
  justify-content: space-between;
  padding: var(--v-space-16);
  background-color: var(--v-color-background-secondary);

  @media (--v-from-md) {
    flex-direction: row;
    gap: var(--v-space-24);
    align-items: center;
    padding: var(--v-space-24);
  }
}
